import { useContext } from 'react';

import { CreatorContext } from '../../../settings/context';
import Functions from '../../../tools/Functions';
import { TgContext } from '../../../settings/context';
import styles from "./TotalPrice.module.scss";
import { local } from '../../../localization/local';

interface TotalPriceInt {
    form: any
    selectedTheme: number | null,
    selectedTopicType: number,
    paymentSpeed: number,
}

export default function TotalPrice({ form, selectedTheme, selectedTopicType, paymentSpeed }: TotalPriceInt) {

    const { creatorInfo } = useContext(CreatorContext);

    const getPaymentSpeedPrice = () => {
        if (selectedTheme !== null){
            return creatorInfo.order[selectedTheme].type_order[Number(selectedTopicType)].cost[paymentSpeed].price;
        }
    }
    const { tgApp } = useContext(TgContext);
    return (
        <div className={styles["total-price"]}>
            <div className={styles["total-price__lines"]}>
                <div className={styles["total-price-line"]}>
                    <p className={styles["total-price-line-text"]}>
                        {local.cost_order}
                    </p>
                    {(tgApp?.initDataUnsafe?.user?.language_code == "ru") ? 
                     <p className={styles["total-price-line-text"]}>{(selectedTheme !== null) ? Functions.delimeterPrice(String(creatorInfo.order[Number(selectedTheme)].price) ?? "0") : "0"}<span>{local.payment_code}</span></p>
                     :
                     <p className={styles["total-price-line-text"]}><span>{local.payment_code}</span>{(selectedTheme !== null) ? Functions.delimeterPrice(String(creatorInfo.order[Number(selectedTheme)].price) ?? "0") : "0"}</p>
                    }
                    
                </div>
                <div className={styles["total-price-line"]}>
                    <p className={styles["total-price-line-text"]}>
                        {local.delivery}
                    </p>
                    {
                    (tgApp?.initDataUnsafe?.user?.language_code == "ru") ? 
                    <p className={styles["total-price-line-text"]}>{(selectedTheme !== null) ? Functions.delimeterPrice(String(getPaymentSpeedPrice())) : "0"}<span>{local.payment_code}</span></p>
                     :
                     <p className={styles["total-price-line-text"]}><span>{local.payment_code}</span> {(selectedTheme !== null) ? Functions.delimeterPrice(String(getPaymentSpeedPrice())) : "0"}</p>
                    }
                    
                </div>
                <div className={styles["total-price-line"]}>
                    <p className={styles["total-price-line-endtext"]}>
                        {local.total}
                    </p>
                    {
                    (tgApp?.initDataUnsafe?.user?.language_code == "ru") ? 
                    <p className={styles["total-price-line-endtext"]}>{(selectedTheme !== null) ? Functions.delimeterPrice(String(Number(creatorInfo.order[Number(selectedTheme)].price ?? 0) + Number(getPaymentSpeedPrice()))) : "0"}{local.payment_code}</p>
                     :
                    <p className={styles["total-price-line-endtext"]}>{local.payment_code}{(selectedTheme !== null) ? Functions.delimeterPrice(String(Number(creatorInfo.order[Number(selectedTheme)].price ?? 0) + Number(getPaymentSpeedPrice()))) : "0"}</p>
                    }
                    
                </div>
            </div>
        </div>
    )
}
