import { useContext,Dispatch, SetStateAction } from 'react';

import Functions from '../../../tools/Functions';
import { TgContext } from '../../../settings/context';
import fire from "../../../images/fire.png";
import sad from "../../../images/sad.png";

import styles from "./RadioGroup.module.scss";
import { local } from '../../../localization/local';

interface RadioGroupInt {
    form: any,
    itemsList: Array<any>,
    additional?: boolean
    selectedTheme: number | null,
    setSelectedTheme: Dispatch<SetStateAction<number>>
}

export default function RadioGroup({ itemsList, additional = false, selectedTheme, setSelectedTheme }: RadioGroupInt) {

    const selectHandler = (id: number) => {
        setSelectedTheme(id);
    }
    const { tgApp } = useContext(TgContext);
    const setGroupsItems = (list: Array<any>) => {
        //@ts-ignore
        return list.map((item: any, index: number) => {
            return (
                <div className={`${styles["radio-group-item"]} ${(item.remains < 1) ? styles["radio-group-item_disable"] : ""} ${(String(selectedTheme) === String(index)) ? styles["radio-group-item_active"] : ""}`} onClick={() => selectHandler(index)}>
                    <div dangerouslySetInnerHTML={{ __html: item.svg }}></div>
                    <p className={styles["radio-group-item-title"]}>{item.title}</p>
                    {(additional) && (<>
                        {(tgApp?.initDataUnsafe?.user?.language_code == "ru") ? <p className={styles["radio-group-item-price"]}>{Functions.delimeterPrice(String(item.price))}{local.payment_code}</p> : <p className={styles["radio-group-item-price"]}>{local.payment_code}{Functions.delimeterPrice(String(item.price))}</p>}
                        
                        <p className={styles["radio-group-item-count"]}>
                            {(item.remains < 1) && <>{local.ops} <img src={sad} alt="fire" /></>}
                            {(item.remains >= 1) && <>{local.left}: {item.remains} <img src={fire} alt="fire" /></>}
                        </p>
                    </>)}
                </div>
            );
        })
    }

    return (
        <div className={styles["radio-group"]}>
            {setGroupsItems(itemsList)}
        </div>
    )
}
