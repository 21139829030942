import { useTWAEvent } from '@tonsolutions/analytics-react';
import { useState, useEffect, useContext, useRef, Dispatch, SetStateAction } from 'react';
import { useFormik } from 'formik';
import Iframe from 'react-iframe'
import * as yup from "yup";

import PaymentConfirmed from '../PaymentConfirmed/PaymentConfirmed';
import CirclePreloader from '../../../UI/Preloader/CirclePreloader';
import ContentExample from '../ContentExample/ContentExample';
import PaymentButton from '../PaymentButton/PaymentButton';
import HeadingInfo from '../HeadingInfo/HeadingInfo';
import OrderDetail from '../OrderDetail/OrderDetail';
import TotalPrice from '../TotalPrice/TotalPrice';
import Button from '../../../UI/Button/Button';
import TopicType from '../TopicType/TopicType';
import InfoBlock from '../InfoBlock/InfoBlock';
import Heading from '../Heading/Heading';
import Order from '../Order/Order';
import Task from '../Task/Task';
import OrderItem from '../Order/OrderItem';
import { CreatorContext, TgContext } from '../../../settings/context';
import { SERVER_URL_IMAGE } from '../../../settings/config';
import DAS_endpoints from '../../../settings/DAS_endpoints';
import Functions from '../../../tools/Functions';
import DAS_HTTP from '../../../tools/DAS_HTTP';
import { laungaugeRussian } from '../../../assets/project/laungaugeRussian';
import { laungaugeEnglisch } from '../../../assets/project/laungaugeEnglisch';
import styles from "./MainModule.module.scss";
import { local } from '../../../localization/local';
import { TRUE } from 'sass';

interface MainModuleInt {
    stage: number,
    setStage: Dispatch<SetStateAction<number>>
}

export default function MainModule({ stage, setStage }: MainModuleInt) {
    const [selectedTheme, setSelectedTheme] = useState<number | null>(0); //radioGroup
    const [selectedTopicType, setSelectedTopicType] = useState<number | null>(0); //radioGroup
    const [paymentSpeed, setPaymentSpeed] = useState<number>(0);
    const [contentType, setContentType] = useState<number>(0);
    const [paymentLink, setPaymentLink] = useState<string>("");
    const [payment, setPayment] = useState<number>(1);
    const [language, setLanguage] = useState<number | null>(0);
    const submitFormRef = useRef(false);
    var random_boolean = Math.random() < 0.5;
    const builder = useTWAEvent();
    
    
    const { creatorInfo } = useContext(CreatorContext);
    const { tgApp } = useContext(TgContext);
    if (tgApp?.initDataUnsafe?.user?.language_code === "ru"){
        local.setLanguage("ru");
    }
    //local.setLanguage("en");
    const form = useFormik({
        initialValues: {
            videoDuration: "",
            type_of_content: null, //RadioGroup
            description: "",
            name_to: "",
            name_for: `${tgApp?.initDataUnsafe?.user?.first_name ?? ""} ${tgApp?.initDataUnsafe?.user?.last_name ?? ""}` ?? "",
        },
        validationSchema: yup.object().shape({
            description: yup.string().max(255, "max")
        }),
        onSubmit: async (values) => {
            if (submitFormRef.current === false && stage === 3) {
                submitFormRef.current = true;
                await DAS_HTTP.request({
                    url: DAS_endpoints.CREATE_ORDER,
                    method: "POST",
                    data: {
                        user_for: tgApp?.initDataUnsafe?.start_param,
                        user_to: tgApp?.initDataUnsafe?.user?.id,
                        theme: creatorInfo.order[Number(selectedTheme)].id,
                        //@ts-ignore
                        language_code:tgApp?.initDataUnsafe?.user?.language_code ?? null,
                        topic_type: creatorInfo.order[selectedTheme].type_order[Number(selectedTopicType)].id,
                        delivery_type: creatorInfo.order[selectedTheme].type_order[Number(selectedTopicType)].cost[paymentSpeed].id,
                        description: values.description,
                        name_for: values.name_for,
                        payment: payment
                    },
                    getData: true
                }).then((res: any) => {
                    submitFormRef.current = false;
                    // getPaymentInfo();
                    // setPaymentLink(res.url);
                    tgApp.openTelegramLink(res.result);
                    // window.location.assign(res.url);
                    // stageHandler(7);
                }).catch((e: any) => {
                    submitFormRef.current = false;
                    console.error(e);
                    stageHandler(4);
                });
            }
        }
    });

    const stageHandler = (stageIndex: number) => {
        setStage(stageIndex);
    }

    // const getPaymentInfo = () => {
    //     let paymentSpeedName: string = "any";
    //     if (selectedTheme !== null) {
    //         const paymentFilterId = creatorInfo.order[form.values.taskItem][selectedTheme].type_order[Number(form.values.videoDuration)].cost.filter((item: any) => {
    //             return Number(paymentSpeed) === Number(item.id);
    //         });
    //         if (paymentFilterId.length > 0) {
    //             paymentSpeedName = paymentFilterId[0].title;
    //         }
    //     }

    //     let paymentSum: number = 0;

    //     if (selectedTheme !== null) {
    //         const firstPrice = creatorInfo.order[form.values.taskItem][selectedTheme].type_order[Number(form.values.videoDuration)].price;
    //         let secondPrice = 0;
    //         const filter = creatorInfo.order[form.values.taskItem][selectedTheme].type_order[Number(form.values.videoDuration)].cost.filter((item: any) => Number(item.id) === Number(checked));
    //         if (filter.length > 0) {
    //             secondPrice = Number(filter[0].price);
    //         }
    //         paymentSum = Number(firstPrice) + Number(secondPrice);
    //     }

    //     builder.track("pageview", { "stage": "confirm", "influencer_id": creatorInfo.user_tg_id });
    //     builder.track("click", { "stage": "confirm", "speed": paymentSpeedName, "sum": paymentSum, "method": "WEB (банковская карта)", "influencer_id": creatorInfo.user_tg_id });
    // }
    useEffect(() => {
        if (stage === 2) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                stageHandler(1);
                builder.track("Возврат обратно", {"stage": "details"});
                tgApp.BackButton.hide();
            });
            
        }
        if (stage === 3) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                if (creatorInfo?.screen_2 == true){
                    stageHandler(2);
                    builder.track("Возврат обратно", {"stage": "confirm"});
                    tgApp.BackButton.hide();
                }
                else{
                    if (creatorInfo?.screen_2_auto == true){
                        if(random_boolean){
                            stageHandler(2);
                            builder.track("Возврат обратно", {"stage": "confirm"});
                            tgApp.BackButton.hide();
                        }
                        else{
                            stageHandler(1);
                            builder.track("Возврат обратно", {"stage": "confirm"});
                            tgApp.BackButton.hide();
                        }
                        
                    }
                    else{
                        stageHandler(1);
                        builder.track("Возврат обратно", {"stage": "confirm"});
                        tgApp.BackButton.hide();
                    }
                }
            });
        }
        if (stage === 7) {
            tgApp?.BackButton.show();
            tgApp?.BackButton.onClick(() => {
                stageHandler(3);
                tgApp.BackButton.hide();
            });
        }
    }, [stage]);

    useEffect(() => {
        if (tgApp.initData !== "" && stage === 1 ) {
            if (tgApp?.initDataUnsafe?.user?.language_code == "ru"){
                tgApp?.MainButton?.setParams({
                    text: creatorInfo?.order ? `${Functions.getOrderEnum(creatorInfo?.order[selectedTheme].theme)} (${Functions.delimeterPrice(creatorInfo?.order[selectedTheme].price ?? "0")}${local.payment_code})` : "   ",
                    color: creatorInfo?.order !== null ? String(tgApp.themeParams.button_color) : "#FFFFFF",
                    text_color: creatorInfo?.order ? String(tgApp.themeParams.button_text_color) : "#FFFFFF",
                    is_visible: true,
                    is_active: true,
                });
            }
            else {
                tgApp?.MainButton?.setParams({
                    text: creatorInfo?.order ? `${Functions.getOrderEnum(creatorInfo?.order[selectedTheme].theme)} (${local.payment_code}${Functions.delimeterPrice(creatorInfo?.order[selectedTheme].price ?? "0")})` : "   ",
                    color: creatorInfo?.order !== null ? String(tgApp.themeParams.button_color) : "#FFFFFF",
                    text_color: creatorInfo?.order ? String(tgApp.themeParams.button_text_color) : "#FFFFFF",
                    is_visible: true,
                    is_active: true,
                });
            }
            
            tgApp?.MainButton.onClick(function () {
                if (creatorInfo?.screen_2 == true){
                    stageHandler(2);
                    builder.track("Переход на детали", {"stage": "profile", "type": creatorInfo.order[selectedTheme].title, "topic": creatorInfo.order[selectedTheme].type_order[selectedTopicType].title, "price": creatorInfo.order[selectedTheme].price});
                    tgApp.MainButton.hide();
                }
                else {
                    if (creatorInfo?.screen_2_auto == true){
                        if(random_boolean){
                            stageHandler(2);
                            builder.track("Переход на детали", {"stage": "profile", "type": creatorInfo.order[selectedTheme].title, "topic": creatorInfo.order[selectedTheme].type_order[selectedTopicType].title, "price": creatorInfo.order[selectedTheme].price});
                            tgApp.MainButton.hide();
                        }
                        else{
                            stageHandler(3);
                            builder.track("Переход на детали", {"stage": "profile", "type": creatorInfo.order[selectedTheme].title, "topic": creatorInfo.order[selectedTheme].type_order[selectedTopicType].title, "price": creatorInfo.order[selectedTheme].price});
                            tgApp.MainButton.hide();
                        }
                    }
                    else{
                        stageHandler(3);
                        builder.track("Переход на детали", {"stage": "profile", "type": creatorInfo.order[selectedTheme].title, "topic": creatorInfo.order[selectedTheme].type_order[selectedTopicType].title, "price": creatorInfo.order[selectedTheme].price});
                        tgApp.MainButton.hide();
                    }
                    
                }
                
            });
        }
    }, [stage, selectedTheme]);

    useEffect(() => {
        if (tgApp.initData !== "" && stage === 2 && form.values.description.length >= 5) {
            if (tgApp?.initDataUnsafe?.user?.language_code == "ru"){
                tgApp.MainButton.setParams({
                    text: `${local.go_to_payment} (${Functions.delimeterPrice(creatorInfo?.order[selectedTheme].price ?? "0")}${local.payment_code})`,
                    color: tgApp.themeParams.button_color,
                    text_color: tgApp.themeParams.button_text_color,
                    is_visible: true,
                    is_active: true,
                });
            }
            else {
                tgApp.MainButton.setParams({
                    text: `${local.go_to_payment} (${local.payment_code}${Functions.delimeterPrice(creatorInfo?.order[selectedTheme].price ?? "0")})`,
                    color: tgApp.themeParams.button_color,
                    text_color: tgApp.themeParams.button_text_color,
                    is_visible: true,
                    is_active: true,
                });
            }
            tgApp.MainButton.onClick(function () {
                builder.track("Переход на подтверждение", {"stage": "details", "for": form.values.name_to, "comments": form.values.description })
                stageHandler(3);
                tgApp.MainButton.hide();
            });
        }
        if (tgApp.initData !== "" && stage === 2 && form.values.description.length < 5) {
            if (tgApp?.initDataUnsafe?.user?.language_code == "ru"){
                tgApp.MainButton.setParams({
                    text: `Заполните описание`,
                    color: tgApp.themeParams.button_color,
                    text_color:  tgApp.themeParams.button_text_color,
                    is_visible: true,
                    is_active: false,
                });
            }
            else {
                tgApp.MainButton.setParams({
                    text: "Put in description",
                    color: tgApp.themeParams.button_color,
                    text_color:  tgApp.themeParams.button_text_color,
                    is_visible: true,
                    is_active: false,
                });
            }
        }
    }, [stage, form]);

    useEffect(() => {
        if (tgApp.initData !== "" && stage === 3) {
            tgApp.MainButton.setParams({
                text: local.go_to_payment,
                color: tgApp.themeParams.button_color,
                text_color: tgApp.themeParams.button_text_color,
                is_visible: true,
                is_active: true,
            });
            tgApp.MainButton.onClick(function () {
                // window.open("https://t.me/$RwT_MqvAMVfdAAAAZLSi8F2qB1M")
                builder.track("Переход на оплату", {"stage": "confirm", "speed": creatorInfo.order[selectedTheme].type_order[Number(selectedTopicType)].cost[paymentSpeed].date, "method": "Yookassa", "sum":Functions.delimeterPrice(creatorInfo?.order[selectedTheme].price) });
                form.submitForm();
                tgApp.MainButton.hide();
            });
        }
    }, [stage]);

    useEffect(() => {
    if (stage === 1) {
         builder.track("Открыли посадочную", {"stage": "profile"})
    }
        if (stage === 2) {
             builder.track("Открыли детали заказа", {"stage": "details"});
        }
        if (stage === 3) {
             builder.track("Открыли подтверждение заказа", {"stage": "confirm"});
        }
        if (stage === 4) {
            builder.track("Ошибка в оплате", {"stage": "error"});
        }
        if (stage === 5) {
            builder.track("Оплата прошла", {"stage": "paid"});
        }
    }, [stage]);

    useEffect(() => {
        setPaymentSpeed(0);
    }, [selectedTheme, selectedTopicType]);

    // useEffect(() => {
    //     // if (creatorInfo.name) {
    //     //     // form.values.description = Functions.getOrderText(creatorInfo?.order[selectedTheme].theme, creatorInfo?.name ?? "Креатор");
    //     //     form.validateForm();
    //     // }
    // }, [selectedTheme, selectedTopicType, creatorInfo]);

    return (
        <section className={(Number(stage) === 3) ? styles["main-module__payment"] : ""}>
            {/* preloader screen */}
            {(stage === 0) && (
                <div className="grid-one center padding">
                    <CirclePreloader />
                </div>
            )}
            {/* Main info screen */}
            {(stage === 1) && (
                <>
                    <div className="grid-one center padding">
                        <Heading link={SERVER_URL_IMAGE + creatorInfo.photo} />
                        <HeadingInfo />
                        <Task
                            form={form}
                            id={"taskItem"}
                            selectedTheme={selectedTheme}
                            setSelectedTheme={setSelectedTheme}
                        />
                        <TopicType
                            form={form}
                            id={"taskItem"}
                            selectedTheme={selectedTheme}
                            selectedTopicType={selectedTopicType}
                            setSelectedTopicType={setSelectedTopicType}
                        />
                        {/* <ContentExample
                            form={form}
                            creatorInfo={creatorInfo}
                            contentType={contentType}
                            setContentType={setContentType}
                        /> */}
                        <div className={styles["order__block"]}>
                            {/* <OrderItem
                                data={{
                                    id: 1,
                                    title: "Русский",
                                    image: laungaugeRussian
                                }}
                                index={1}
                                server={false}
                                checked={language}
                                setChecked={setLanguage}
                            />
                            <OrderItem
                                data={{
                                    id: 2,
                                    title: "Englich",
                                    image: laungaugeEnglisch
                                }}
                                index={2}
                                server={false}
                                checked={language}
                                setChecked={setLanguage}
                            /> */}
                        </div>
                    </div>
                    <InfoBlock />
                </>
            )}
            {/* Detail screen */}
            {(stage === 2) && (
                <div className="grid-two center padding">
                    <Heading link={SERVER_URL_IMAGE + creatorInfo.photo} />
                    <OrderDetail
                        form={form}
                    />
                </div>
            )}
            {/* Payment detail screen */}
            {(stage === 3) && (
                <>
                    <div className="grid-three center padding">
                        <Order
                            form={form}
                            checked={paymentSpeed}
                            setChecked={setPaymentSpeed}
                            selectedTheme={selectedTheme}
                            selectedTopicType={selectedTopicType}
                            payment={payment}
                            setPayment={setPayment}
                        />

                    </div>
                    <div>
                        <TotalPrice form={form} selectedTheme={selectedTheme} selectedTopicType={selectedTopicType} paymentSpeed={paymentSpeed} />
                    </div>
                </>
            )}
            {/* Confirm order screen */}
            {(stage === 4) && (
                <>
                    <div className="grid-three center padding">
                        <PaymentConfirmed mode="error" />
                    </div>
                </>
            )}
            {/* Create order or payment error screen */}
            {(stage === 5) && (
                <>
                    <div className="grid-three center padding">
                        <PaymentConfirmed mode="confirm" />
                    </div>
                </>
            )}
            {/* Server or connection error screen */}
            {(stage === 6) && (
                <>
                    <div className="grid-three center padding">
                        <PaymentConfirmed mode="connectionError" />
                    </div>
                </>
            )}
            {/* Payment */}
            {(stage === 7 && paymentLink) && (
                <div>
                    <Iframe url={"https://www.google.com/"} width="100%"
                        height="100%"
                        id=""
                        className=""
                        display="block"
                        position="relative" />
                </div>
            )}
        </section>
    )
}
